<header *ngIf="!isOnboardingRoute">
  <div [ngClass]="hideSidenav ? 'logo hide-sidenav' : 'logo open-sidenav'"></div>

  <nz-header>
    <div class="header-container">
      <span class="example-spacer"></span>
      <img
        src="../../assets/images/icons/circle-chat.svg"
        alt="Chat para falar com a Gen"
        class="pointer-cursor"
        (click)="toggleChat()"
      />

      <a class="suport" href="https://suporte.gen.com.br/conhecimento" target="_blank" rel="noopener">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.8125 9.50224C9.9887 9.00136 10.3365 8.579 10.7942 8.30998C11.252 8.04095 11.7902 7.9426 12.3135 8.03237C12.8368 8.12213 13.3115 8.39421 13.6534 8.80041C13.9954 9.20661 14.1825 9.72072 14.1817 10.2517C14.1817 11.7506 11.9334 12.5 11.9334 12.5M11.9609 15.5H11.9709M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
            stroke="#C9C9C9"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>

      <nz-badge>
        <button
          class="button-content m-r-1"
          nz-popover
          [nzPopoverTitle]="titleNotificationTemplate"
          [nzPopoverContent]="notificationTemplate"
          nzPopoverPlacement="bottomRight"
          nzPopoverTrigger="click"
          nzPopoverOverlayClassName="notificationTemplate"
          [(nzPopoverVisible)]="isPopoverNotificationVisible"
        >
          <a *ngIf="unRead.length" class="head-example"></a>
          <img src="../../assets/images/icons/bell-alt.svg" alt="Sino" />
        </button>
      </nz-badge>

      <ng-template #titleNotificationTemplate>
        <div class="title__popOver d-flex between">
          <h1>
            Notificações <span> ({{ unRead.length }})</span>
          </h1>
          <button class="button-content m-r-1" *ngIf="unRead?.length > 0 || read?.length > 0" (click)="markAllAsRead()">
            Marcar todas como lidas
          </button>
        </div>
      </ng-template>

      <ng-template #notificationTemplate class="contentTemplate">
        <ng-container *ngIf="unRead?.length > 0">
          <div
            class="notification-content notification-content__unRead"
            *ngFor="let unReads of unReadLimited"
            (click)="redirectNotifications(unReads)"
          >
            <img
              class="notification-icon"
              src="../../assets/images/icons/circle-dollar.svg"
              alt="Dolar"
              *ngIf="unReads.action === 'payment'"
            />
            <div class="notification-icon notification-icon__subscription" *ngIf="unReads.action === 'subscription'">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 12.3333L10.4615 15L16 9M7.80039 4.63683C8.52384 4.57909 9.21064 4.29461 9.76302 3.82388C11.0521 2.72537 12.9479 2.72537 14.237 3.82388C14.7894 4.29461 15.4762 4.57909 16.1996 4.63683C17.8878 4.77155 19.2285 6.11215 19.3632 7.80039C19.4209 8.52384 19.7054 9.21064 20.1761 9.76302C21.2746 11.0521 21.2746 12.9479 20.1761 14.237C19.7054 14.7894 19.4209 15.4762 19.3632 16.1996C19.2285 17.8878 17.8878 19.2285 16.1996 19.3632C15.4762 19.4209 14.7894 19.7054 14.237 20.1761C12.9479 21.2746 11.0521 21.2746 9.76302 20.1761C9.21064 19.7054 8.52384 19.4209 7.80039 19.3632C6.11215 19.2285 4.77155 17.8878 4.63683 16.1996C4.57909 15.4762 4.29461 14.7894 3.82388 14.237C2.72537 12.9479 2.72537 11.0521 3.82388 9.76302C4.29461 9.21064 4.57909 8.52384 4.63683 7.80039C4.77155 6.11215 6.11215 4.77155 7.80039 4.63683Z"
                  stroke="#0B6C97"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <img
              class="notification-icon notification-icon__withdraw"
              src="../../assets/images/icons/money-withdrawal.svg"
              alt="Saque"
              *ngIf="unReads.action === 'withdraw-waiting'"
            />

            <span class="description__popOver d-block">{{ unReads.description || '-' }}</span>
          </div>
        </ng-container>

        <div class="footer center">
          <a (click)="redirectToNotifications()"> Ver todas as notificações </a>
        </div>
      </ng-template>

      <div nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" class="profile">
        <div class="h-100">
          <p
            [ngClass]="
              seller?.store?.displayName || seller?.company?.businessName || seller?.occupation
                ? 'name name__business'
                : 'name name__personal'
            "
          >
            {{ seller?.person?.name || user?.name }}
          </p>
          <ng-container *ngIf="seller?.status === 'ACTIVE'">
            <span *ngIf="seller?.store?.displayName || seller?.company?.businessName || seller?.occupation">{{
              seller?.store?.displayName || seller?.company?.businessName || seller?.occupation
            }}</span>
          </ng-container>
          <ng-container *ngIf="seller?.status === 'NEW' || seller?.status === 'IN_PROGRESS'">
            <span class="m-status">
              <a class="primary-color" (click)="preventDefault($event)" routerLink="/internal/onboarding"
                >Ativar Conta</a
              >
            </span>
          </ng-container>
          <ng-container *ngIf="seller?.status === 'IN_ANALYSIS'">
            <span class="m-status">
              Conta <app-status-tag *ngIf="seller?.status" [type]="seller?.status" gender="male"> </app-status-tag>
            </span>
          </ng-container>
        </div>

        <img src="../../assets/images/icons/chevron-down.svg" alt="Seta pra baixo" />
      </div>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <div class="dropdown-container">
          <div class="logo-profile">
            <nz-avatar [nzSize]="40" nzIcon="user" [nzSrc]="user.avatar"></nz-avatar>
          </div>

          <div class="profile-info">
            <p>{{ seller?.person?.name || user?.name }}</p>
            <span class="text-seller-name d-block">
              {{ seller?.store?.displayName || seller?.company?.businessName || seller?.occupation }}
            </span>
            <span class="text-email d-block">{{ seller.email || user.email }}</span>

            <hr />

            <div class="button-footer">
              <a (click)="redirectToProfile()">Perfil e preferências</a>
              <a (click)="logout()">Sair</a>
            </div>
          </div>
        </div>
      </nz-dropdown-menu>
    </div>
  </nz-header>
</header>

<header id="onboarding-header" *ngIf="isOnboardingRoute">
  <div class="go-back" (click)="goBack(title)">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M6 12H18M6 12L11 7M6 12L11 17"
        stroke="#6D54FC"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <p>Voltar</p>
  </div>

  <img
    class="pointer-cursor"
    src="../../assets/images/icons/new-logo-gen.svg"
    alt="Logo da Gen"
    [routerLink]="'/internal'"
  />

  <div class="right-container">
    <div class="need-help">
      <a href="https://suporte.gen.com.br/conhecimento" rel="noopener noreferrer" target="_blank">Precisa de ajuda?</a>
      <img src="../../assets/images/icons/circle-question-blue.svg" alt="Ícone de interrogação" />
    </div>

    <img
      (click)="goBack('businessDatails')"
      src="../../assets/images/icons/xmark.svg"
      alt="Ícone para sair do onboarding"
    />
  </div>
</header>
