import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shoppingAcronym',
})
export class ShoppingAcronymPipe implements PipeTransform {
  transform(value: any, ...args: string[]): string {
    if (value) {
      const acronyms: { [key: number]: string } = {
        1: 'Maia',
        2: 'Barueri',
        3: 'Bonsucesso',
        4: 'Cascavel',
        5: 'Sulacap',
        6: 'Vale',
        7: 'Unimart',
        8: 'OPBSB',
        9: 'OPRJ',
        10: 'OPSSA',
        11: 'OPSP',
        12: 'OPFOR',
        13: 'Poli',
        14: 'OPGSP',
      };

      return acronyms[value] || '';
    }

    return '';
  }
}
