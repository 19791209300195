import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Message } from '../../utils/message';
import { LeadModel } from '../../models/lead/lead.model';
import { LeadService } from '../../services/lead/lead.service';
import { SellerModel } from '../../models/sellers/sellers.model';
import { ErrorService } from '../../services/error/error.service';
import { CouponFidelityModel } from '../../models/fidelity/coupon-fidelity.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { ModalLeadOnboardingComponent } from '../modals/modal-lead-onboarding/modal-lead-onboarding.component';

@Component({
  selector: 'app-coupon-details',
  templateUrl: './coupon-details.component.html',
  styleUrls: ['./coupon-details.component.scss'],
})
export class CouponDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('loadMore') loadMore: ElementRef;
  @Input() public mode: string = 'ALL';
  @Input() public reference: string = '';

  public coupons: Array<CouponFidelityModel> = [];
  public filterData: Array<Partial<CouponFidelityModel>> = [];
  public lead: LeadModel = new LeadModel();
  public seller: SellerModel = new SellerModel();
  public loadValue: number = 10;
  public loadingMore: boolean = false;
  public stopLoading: boolean = false;
  public loading: boolean = true;
  public isMobile: boolean = window.innerWidth < 768;

  private filterForm: any = {};
  private destroy$ = new Subject();

  constructor(
    private $lead: LeadService,
    private $error: ErrorService,
    private readonly router: Router,
    private readonly $modal: NzModalService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.getLead();
    this.getNotification();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(1);
    this.destroy$.complete();
  }

  private getLead(): void {
    this.$notification.leads.subscribe((lead) => {
      if (lead?.id) {
        this.lead = lead;
      }

      if (this.mode === 'ALL') {
        this.getAllCoupons(lead?.id);
      } else if (this.mode === 'MY_COUPONS') {
        this.getMyCoupons();
      } else if (this.mode === 'CATEGORY') {
        this.getCouponsByCategoryId(lead?.id);
      } else {
        this.getCouponsBySellerId(lead?.id);
      }
    });
  }

  private getNotification(): void {
    this.$notification.filterForms.subscribe((filterForm) => {
      if (filterForm) {
        this.filterForm = filterForm;

        if (this.mode === 'MY_COUPONS') {
          this.getMyCoupons();
        } else if (this.mode === 'CATEGORY') {
          this.getCouponsByCategoryId(this.lead?.id);
        }
      }
    });
  }

  private getAllCoupons(leadId?: string): void {
    this.$lead
      .getCouponsByLeadId(leadId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.couponsLead) {
            this.coupons = JSON.parse(JSON.stringify(res.data.couponsLead));
            this.filterData = this.coupons.slice(0, this.loadValue);
            this.loadingMore = this.coupons.length <= this.loadValue;
          }

          setTimeout(() => {
            this.loading = false;
          }, 1500);
        },
        error: (error) => {
          this.loading = false;
          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
  }

  private getMyCoupons(): void {
    this.$lead
      .getMyCoupons(this.filterForm)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.leadRescuedCoupon) {
            this.coupons =
              this.reference === 'ACTIVE'
                ? JSON.parse(JSON.stringify(res.data.leadRescuedCoupon?.actives))
                : JSON.parse(JSON.stringify(res.data.leadRescuedCoupon?.inactives));
            this.filterData = this.coupons.slice(0, this.loadValue);
            this.loadingMore = this.coupons.length <= this.loadValue;
          }

          setTimeout(() => {
            this.loading = false;
          }, 1500);
        },
        error: (error) => {
          this.loading = false;
          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
  }

  private getCouponsByCategoryId(leadId?: string): void {
    this.$lead
      .getCouponsByCategoryId(leadId, this.reference, this.filterForm)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.allCategoriesCouponLead) {
            this.coupons = res.data.allCategoriesCouponLead?.[0]?.coupons
              ? JSON.parse(JSON.stringify(res.data.allCategoriesCouponLead?.[0].coupons))
              : [];
            this.filterData = this.coupons.slice(0, this.loadValue);
            this.loadingMore = this.coupons.length <= this.loadValue;
          }

          setTimeout(() => {
            this.loading = false;
          }, 1500);
        },
        error: (error) => {
          this.loading = false;
          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
  }

  private getCouponsBySellerId(leadId: string): void {
    this.$lead
      .getCouponsBySellerId(this.reference, leadId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.sellerToCoupon) {
            this.seller = JSON.parse(JSON.stringify(res.data.sellerToCoupon));
            this.coupons = this.seller?.coupons ? this.seller.coupons : [];
            this.filterData = this.coupons.slice(0, this.loadValue);
            this.loadingMore = this.coupons.length <= this.loadValue;
          }

          setTimeout(() => {
            this.loading = false;
          }, 1500);
        },
        error: (error) => {
          this.loading = false;
          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
  }

  public useCoupon(coupon: Partial<CouponFidelityModel>): void {
    this.router.navigate([`/details/${coupon.id}`]);
  }

  public likeCoupon(coupon: Partial<CouponFidelityModel>): void {
    if (this.lead.id) {
      if (coupon.isFavorite) {
        this.$lead
          .unlikeCoupon(coupon.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (res) => {
              if (res?.data?.removeLeadLikeCoupon) {
                coupon.isFavorite = !coupon.isFavorite;
              }
            },
            error: (error) => {
              this.$error.errorHandling(
                error,
                'Erro ao retirar o like no cupom. Por favor, tente novamente mais tarde.'
              );
            },
          });
      } else {
        this.$lead
          .likeCoupon(coupon.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (res) => {
              if (res?.data?.addLeadLikeCoupon) {
                coupon.isFavorite = !coupon.isFavorite;
              }
            },
            error: (error) => {
              this.$error.errorHandling(error, 'Erro ao dar like no cupom. Por favor, tente novamente mais tarde.');
            },
          });
      }
    } else {
      this.showLoginModal();
    }
  }

  public showMore(): void {
    this.stopLoading = true;
    this.filterData = this.filterData.concat([...Array(10)].fill({}).map(() => ({ loading: true, name: '' })));

    setTimeout(() => {
      this.loadValue += 10;
      this.filterData = this.coupons.slice(0, this.loadValue);
      this.loadingMore = this.loadValue > this.coupons.length;
      this.stopLoading = false;
    }, 1500);
  }

  private showLoginModal(): void {
    this.$modal.create({
      nzTitle: null,
      nzContent: ModalLeadOnboardingComponent,
      nzFooter: null,
      nzCentered: true,
      nzClosable: true,
      nzClassName: 'ant-modal-fullscreen',
    });
  }

  public trackByFn(index: number, coupon: Partial<CouponFidelityModel>): string {
    return coupon.id;
  }

  public shouldLoadMoreItems(): boolean {
    if (this.loadMore?.nativeElement) {
      const element = this.loadMore.nativeElement;
      const elementTop = element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const minimumTopMargin = 100;

      return elementTop - minimumTopMargin <= windowHeight;
    }

    return false;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    if (!this.loadingMore && this.shouldLoadMoreItems() && !this.stopLoading) {
      this.showMore();
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.isMobile = event.target.innerWidth < 768;
  }
}
