import { Subject, interval, takeUntil } from 'rxjs';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ViewChildren, Output, EventEmitter } from '@angular/core';

import { Message } from 'libs/shared/src/lib/utils/message';
import { LeadService } from 'libs/shared/src/lib/services/lead/lead.service';
import { CustomAuthService } from 'libs/shared/src/lib/services/auth/custom-auth/custom-auth.service';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { AuthService } from 'libs/shared/src/lib/services/auth/auth-business/auth.service';

@Component({
  selector: 'app-confirm-lead-phone-number',
  templateUrl: './confirm-lead-phone-number.component.html',
  styleUrls: ['./confirm-lead-phone-number.component.scss'],
})
export class ConfirmLeadPhoneNumberComponent implements OnInit {
  @ViewChildren('input') inputsList!: any;
  @Output() public changePhoneNumber = new EventEmitter<boolean>();
  @Output() public finishModal = new EventEmitter<boolean>();
  @Output() public alertData = new EventEmitter<any>();

  public formConfirmPhone!: FormGroup;
  public countdown: number = 60;
  public ispause = new Subject();
  public loading: boolean = false;
  public allowResend: boolean = false;
  public phoneNumber: string = '';

  constructor(
    private $lead: LeadService,
    private $auth: AuthService,
    private readonly fb: FormBuilder,
    private $customAuth: CustomAuthService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getTimer();
    this.getPhoneNumber();
  }

  private getPhoneNumber(): void {
    this.$notification.phoneNumbers.subscribe((res) => {
      if (res) {
        this.phoneNumber = res;
      }
    });
  }

  private createForm(): void {
    this.formConfirmPhone = this.fb.group({
      code1: new FormControl('', [Validators.required]),
      code2: new FormControl('', [Validators.required]),
      code3: new FormControl('', [Validators.required]),
      code4: new FormControl('', [Validators.required]),
      code5: new FormControl('', [Validators.required]),
      code6: new FormControl('', [Validators.required]),
    });
  }

  public validatePhoneNumberCode(): void {
    this.loading = true;

    const code =
      this.formConfirmPhone.get('code1').value +
      this.formConfirmPhone.get('code2').value +
      this.formConfirmPhone.get('code3').value +
      this.formConfirmPhone.get('code4').value +
      this.formConfirmPhone.get('code5').value +
      this.formConfirmPhone.get('code6').value;

    const payload = {
      code,
      phoneNumber: this.phoneNumber,
      type: 'SIGN_IN_PHONE_NUMBER',
    };

    this.$auth.validateVerificationCode(payload, 'LEAD').subscribe({
      next: (res) => {
        if (res?.data?.validateVerificationCode) {
          sessionStorage.setItem('accessToken', res.data.validateVerificationCode?.authToken);
          this.$customAuth.setAuth(res.data.validateVerificationCode?.authToken);
          this.openAlert('success', 'Login realizado com sucesso!');

          setTimeout(() => {
            this.$notification.setIsLoggedIn(true);
            this.loading = false;
            this.finishModal.emit(true);
          }, 2000);
        } else {
          this.loading = false;
        }
      },
      error: (error) => {
        this.loading = false;
        this.openAlert('error', Message.ERROR_CONECTION, error);
      },
    });
  }

  public onInput(event: any, index: number): void {
    if (event.data) {
      this.inputsList._results[index].nativeElement.focus();
    }
  }

  public onPressDeleteKey(event: KeyboardEvent, index: number): void {
    if (event.key === 'Backspace') {
      const input = this.inputsList._results[index];
      if (input) {
        if (index === 5) {
          input.nativeElement.value = null;
          input.nativeElement.focus();
        }
        const previousInput = this.inputsList._results[index - 1];
        if (previousInput) {
          previousInput.nativeElement.value = null;
          previousInput.nativeElement.focus();
        }
      }
    }
  }

  public changeNumber(): void {
    this.changePhoneNumber.emit(false);
  }

  public getTimer(): void {
    interval(1000)
      .pipe(takeUntil(this.ispause))
      .subscribe({
        next: (_: number) => {
          if (this.countdown === 1) {
            this.ispause.next(true);
            this.allowResend = true;
          }
          this.countdown--;
        },
      });
  }

  public resendCode(): void {
    if (this.countdown < 1) {
      this.allowResend = false;

      this.$lead.sendSms(this.phoneNumber).subscribe({
        next: (resp) => {
          if (resp?.data?.sendAuthLead) {
            this.countdown = 60;
            this.getTimer();
            this.openAlert('success', 'SMS enviado com sucesso!');
          }

          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.openAlert('error', Message.ERROR_CONECTION, error);
        },
      });
    }
  }

  public pasteNumeric(event: ClipboardEvent): void {
    if (event.clipboardData) {
      const code = event.clipboardData.getData('text').replace(/[^0-9]/g, '');

      this.formConfirmPhone.setValue({
        code1: code[0] || '',
        code2: code[1] || '',
        code3: code[2] || '',
        code4: code[3] || '',
        code5: code[4] || '',
        code6: code[5] || '',
      });

      if (code.length === 6) {
        setTimeout(() => {
          this.validatePhoneNumberCode();
          event.preventDefault();
        }, 500);
      }
    }
  }

  public openAlert(type: string, message: string, error?: any): void {
    if (error) {
      const parseError = JSON.parse(JSON.stringify(error));
      message = parseError.message.length > 50 ? parseError.message.substring(0, 50) + '...' : parseError.message;
    }

    this.alertData.emit({
      type,
      message,
    });
  }

  public keyDownFunction(event: any): void {
    event.preventDefault();

    if (this.formConfirmPhone.valid) {
      this.validatePhoneNumberCode();
    } else {
      Object.values(this.formConfirmPhone.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
