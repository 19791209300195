import { gql } from 'apollo-angular';

const CAMPAIGNS_QUERY = gql`
  query Campaigns($filters: FilterCampaignsParamsInput, $pagination: PaginationParams) {
    campaigns(filters: $filters, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        id
        hasSms
        hasEmail
        startDate
        endDate
        sendDate
        status
        segmentations {
          id
          name
          description
          contacts {
            id
            fullName
            created_at
            updated_at
            cpf
            email
            phoneNumber
            isLegalEntity
            status
          }
        }
        seller {
          id
          cnpj
          business_name
        }
      } 
    }
  }
`;

const CAMPAIGN_QUERY = gql`
  query Campaign($id: String!) {
    campaign(id: $id) {
      id
      sellerId
      seller {
        id
        cnpj
        business_name
      }
      hasEmail
      hasSms
      sms {
        id
        body_message
        campaign_id
      }
      email {
        id
        campaign_id
        subject_email
        title_message
        body_message
        template_sendgrid_id
        link_action
        action_name
      }
      segmentations {
        id
        name
        description
        contacts {
          id
          fullName
          created_at
          updated_at
          cpf
          email
          phoneNumber
          isLegalEntity
          status
        }
      }
      customName
      sendDate
      sendMessages
      startDate
      endDate
      status
    }
  }
`;

export { CAMPAIGNS_QUERY, CAMPAIGN_QUERY };
