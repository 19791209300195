import * as Sentry from '@sentry/angular-ivy';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@gen/environments';

if (environment.production) {
  Sentry.init({
    dsn: 'https://26236fb4df5f4babbb452bf5241e8618@sentry.gen.com.br/4',
    environment: 'production',
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: ['https://checkout.gen.com.br'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    ignoreErrors: [
      'Firebase Storage',
      'ChunkLoadError',
      'INTERNAL ASSERTION FAILED',
      'A network error',
      "The user's credential is no longer valid. The user must sign in again.",
      'Missing or insufficient permissions.',
      'Failed to get document because the client is offline.',
      'Attempt to get a record from database without an in-progress transaction',
    ],
    tracesSampleRate: 0.7,
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
