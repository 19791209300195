<section id="coupon-details">
  <div class="center m-t-2 m-b-2 p-b-2 p-t-2" *ngIf="loading">
    <nz-spin nzSize="large"></nz-spin>
  </div>
  <ng-container *ngIf="!loading">
    <ng-container *ngIf="filterData && filterData.length > 0; else noData">
      <nz-list nzGrid>
        <nz-row [nzGutter]="32">
          <nz-col
            [nzXs]="24"
            [nzSm]="24"
            [nzMd]="24"
            [nzLg]="24"
            [nzXl]="12"
            [nzXXl]="12"
            *ngFor="let coupon of filterData; trackBy: trackByFn"
          >
            <nz-list-item class="margin-list">
              <ng-container *ngIf="!coupon.loading">
                <nz-card class="coupon-card pointer-cursor" (click)="useCoupon(coupon)">
                  <nz-row [nzGutter]="{ xs: 16, sm: 16, md: 24, lg: 24, xl: 24, xxl: 24 }" class="image-bottom">
                    <nz-col [nzXs]="7" [nzSm]="4" [nzMd]="5" [nzLg]="4" [nzXl]="7" [nzXXl]="5">
                      <nz-row class="w-100">
                        <nz-col nzSpan="24">
                          <img
                            [ngClass]="reference === 'INACTIVE' ? 'coupon-image inactive-image' : 'coupon-image'"
                            [src]="coupon?.coupon_image || coupon?.seller?.image_url"
                            onerror="this.onerror=null; this.src='/assets/img/default-coupon.svg'"
                            alt="Imagem do cupom"
                          />
                        </nz-col>
                      </nz-row>
                    </nz-col>
                    <nz-col
                      [nzXs]="17"
                      [nzSm]="20"
                      [nzMd]="19"
                      [nzLg]="20"
                      [nzXl]="17"
                      [nzXXl]="19"
                      class="coupon-data"
                    >
                      <nz-row class="w-100">
                        <nz-col [nzXs]="21" [nzSm]="21" [nzMd]="21" [nzLg]="22" [nzXl]="22" [nzXXl]="22">
                          <div class="d-flex">
                            <span class="coupon-title">
                              {{ coupon?.seller?.business_name || seller?.business_name }}
                              <span *ngIf="coupon?.mall?.name" class="coupon-title desktop">
                                - {{ coupon.mall.name }}
                              </span>
                            </span>
                          </div>
                          <div class="mobile" *ngIf="coupon?.mall?.name">
                            <span class="coupon-subtitle">{{ coupon.mall.name }}</span>
                          </div>
                        </nz-col>
                        <nz-col
                          [nzXs]="3"
                          [nzSm]="3"
                          [nzMd]="3"
                          [nzLg]="2"
                          [nzXl]="2"
                          [nzXXl]="2"
                          class="endBlock"
                          *ngIf="reference !== 'INACTIVE'"
                        >
                          <div class="pointer-cursor" (click)="likeCoupon(coupon); $event.stopPropagation()">
                            <img
                              [src]="coupon.isFavorite ? '/assets/img/red-heart.svg' : '/assets/img/empty-heart.svg'"
                              alt="Icone de coração"
                              class="icon-img"
                            />
                          </div>
                        </nz-col>
                      </nz-row>
                      <nz-row *ngIf="reference !== 'INACTIVE' || !isMobile" class="w-100">
                        <nz-col nzSpan="24">
                          <span class="coupon-description">
                            {{
                              coupon.description
                                ? coupon.description.length > 35
                                  ? coupon.description.substring(0, 35) + '...'
                                  : coupon.description
                                : ''
                            }}
                          </span>
                        </nz-col>
                      </nz-row>
                      <nz-row *ngIf="reference === 'INACTIVE'">
                        <nz-col nzSpan="24" class="mobile">
                          <div class="inactive-tag-box">
                            <span class="inactive-tag-text">
                              {{ coupon.redeemed ? 'Utilizado' : 'Expirado' }}
                            </span>
                          </div>
                        </nz-col>
                        <nz-col *ngIf="coupon.redeemed" nzSpan="24" class="desktop">
                          <span class="coupon-description">
                            Resgatado em {{ coupon?.redeemedAt | date : 'dd/MM/yyyy' }}
                          </span>
                        </nz-col>
                      </nz-row>
                      <nz-row *ngIf="reference !== 'INACTIVE'" class="w-100 desktop">
                        <nz-col nzSpan="24">
                          <div class="d-flex">
                            <div class="m-r-0-1">
                              <nz-rate [ngModel]="coupon.couponRating" nzDisabled nzAllowHalf></nz-rate>
                            </div>
                            <div>
                              <span class="coupon-description">
                                {{ coupon.couponRating }} ({{
                                  coupon.totalAvaliations === 1
                                    ? '1 avaliação'
                                    : coupon.totalAvaliations + ' avaliações'
                                }})
                              </span>
                            </div>
                          </div>
                        </nz-col>
                      </nz-row>
                    </nz-col>
                  </nz-row>
                  <nz-row [nzGutter]="isMobile ? 12 : 0" class="w-100">
                    <nz-col [nzXs]="7" [nzSm]="4" [nzMd]="24" [nzLg]="24" [nzXl]="24" [nzXXl]="24">
                      <div [ngClass]="reference === 'INACTIVE' ? 'tag-box inactive-image' : 'tag-box'">
                        <span [ngClass]="reference === 'INACTIVE' ? 'tag-text inactive-image' : 'tag-text'">
                          {{
                            coupon.typeDiscount === 'PERCENT'
                              ? coupon.value / 100 + '%'
                              : 'R$' + (coupon.value / 100 | currency : ' ' : 'symbol' : '1.0-0')
                          }}
                          OFF{{ !isMobile ? ' EM PRODUTOS SELECIONADOS' : '' }}
                        </span>
                      </div>
                    </nz-col>
                    <nz-col [nzXs]="17" [nzSm]="20" [nzMd]="0" [nzLg]="0" [nzXl]="0" [nzXXl]="0" class="mobile">
                      <div *ngIf="reference !== 'INACTIVE'" class="d-flex">
                        <div class="m-r-0-1">
                          <nz-rate [ngModel]="coupon.couponRating" [nzCount]="1" nzDisabled nzAllowHalf></nz-rate>
                        </div>
                        <div>
                          <span class="coupon-description">
                            {{ coupon.couponRating }} ({{
                              coupon.totalAvaliations === 1 ? '1 avaliação' : coupon.totalAvaliations + ' avaliações'
                            }})
                          </span>
                        </div>
                      </div>
                      <div *ngIf="coupon.redeemed && reference === 'INACTIVE'">
                        <span class="redeem-text">Resgatado em {{ coupon?.redeemedAt | date : 'dd/MM/yyyy' }}</span>
                      </div>
                    </nz-col>
                  </nz-row>
                </nz-card>
              </ng-container>
              <nz-card *ngIf="coupon.loading" class="coupon-card">
                <nz-skeleton [nzActive]="true" [nzLoading]="true" [nzAvatar]="{ size: 'large' }"> </nz-skeleton>
              </nz-card>
            </nz-list-item>
          </nz-col>
        </nz-row>
      </nz-list>
      <nz-row #loadMore class="w-100 m-b-1"></nz-row>
    </ng-container>
    <ng-template #noData>
      <section class="m-t-2">
        <nz-row *ngIf="mode !== 'MY_COUPONS'" class="m-b-1 center">
          <nz-col nzSpan="24">
            <span class="no-data-text">Essa sessão está vazia.</span>
          </nz-col>
        </nz-row>
        <nz-row *ngIf="mode !== 'STORE'" class="m-b-1 center">
          <nz-col nzSpan="24">
            <span class="no-data-text">
              {{
                mode === 'MY_COUPONS'
                  ? 'Você ainda não curtiu ou resgatou nenhum cupom.'
                  : 'Mas não se preocupe. Você pode ver outras categorias.'
              }}
            </span>
          </nz-col>
        </nz-row>
      </section>
      <nz-row class="center m-b-1" *ngIf="reference !== 'INACTIVE'">
        <nz-col nzSpan="24">
          <app-primary-button routerLink="/" class="center"> Ver cupons </app-primary-button>
        </nz-col>
      </nz-row>
    </ng-template>
  </ng-container>
</section>
