import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { ResponseModel } from '../../models/response/response.model';
import { ApplicationModel } from '../../models/application/application.model';
import { Apollo, Query } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import {
  CREATE_APPLICATION_MUTATION,
  UPDATE_APPLICATION_MUTATION,
} from '../../graphql/mutations/applications.mutation';
import { LIST_APPLICATIONS_QUERY } from '../../graphql/queries/applications.queries';

@Injectable()
export class ApplicationsService {
  constructor(private apollo: Apollo) {}

  public createApplication(params: any): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_APPLICATION_MUTATION,
      variables: {
        params,
      },
    });
  }

  public updateApplication(id: string, params: any): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_APPLICATION_MUTATION,
      variables: {
        id,
        params,
      },
    });
  }

  public listApplications(filters: { sellerId: string }): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_APPLICATIONS_QUERY,
      variables: { filters },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
}
