import { gql } from 'apollo-angular';

const CREATE_CAMPAIGNS_MUTATION = gql`
  mutation CreateCampaign(
    $sellerId: String!
    $startDate: DateTime!
    $sendDate: DateTime!
    $hasSms: Boolean
    $hasEmail: Boolean
    $segmentationIds: [String!]
    $smsBodyMessage: String
    $emailBodyMessage: String
    $emailTitle: String
    $emailSubject: String
    $link: String
    $templateId: String
    $actionName: String
    $customName: String
  ) {
    createCampaign(
      sellerId: $sellerId
      startDate: $startDate
      sendDate: $sendDate
      hasSms: $hasSms
      hasEmail: $hasEmail
      segmentationIds: $segmentationIds
      smsBodyMessage: $smsBodyMessage
      emailBodyMessage: $emailBodyMessage
      emailTitle: $emailTitle
      emailSubject: $emailSubject
      link: $link
      templateId: $templateId
      actionName: $actionName
      customName: $customName
    ) {
      id
    }
  }
`;

const UPDATE_CAMPAIGNS_MUTATION = gql`
  mutation UpdateCampaign(
    $id: String!
    $sellerId: String!
    $startDate: DateTime!
    $sendDate: DateTime!
    $hasSms: Boolean
    $hasEmail: Boolean
    $segmentationIds: [String!]
    $smsBodyMessage: String
    $emailBodyMessage: String
    $emailTitle: String
    $emailSubject: String
    $link: String
    $templateId: String
    $actionName: String
    $customName: String
  ) {
    updateCampaign(
      id: $id
      sellerId: $sellerId
      startDate: $startDate
      sendDate: $sendDate
      hasSms: $hasSms
      hasEmail: $hasEmail
      segmentationIds: $segmentationIds
      smsBodyMessage: $smsBodyMessage
      emailBodyMessage: $emailBodyMessage
      emailTitle: $emailTitle
      emailSubject: $emailSubject
      link: $link
      templateId: $templateId
      actionName: $actionName
      customName: $customName
    ) {
      id
    }
  }
`;

const DUPLICATE_CAMPAIGN_MUTATION = gql`
  mutation DuplicateCampaign($id: [String!]!) {
    duplicateCampaign(id: $id) {
      id
    }
  }
`;

const DELETE_CAMPAIGN_MUTATION = gql`
  mutation DeleteCampaign($id: String!) {
    deleteCampaign(id: $id) {
      id
    }
  }
`;

export { CREATE_CAMPAIGNS_MUTATION, UPDATE_CAMPAIGNS_MUTATION, DUPLICATE_CAMPAIGN_MUTATION, DELETE_CAMPAIGN_MUTATION };
