import { NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Component, HostListener, OnInit } from '@angular/core';

import { StateManagementService } from '../../state-management/state-management.service';
import { ModalFilterCouponComponent } from '../modals/modal-filter-coupon/modal-filter-coupon.component';
import { DrawerFilterCouponComponent } from '../drawers/drawer-filter-coupon/drawer-filter-coupon.component';

@Component({
  selector: 'app-coupon-sub-header',
  templateUrl: './coupon-sub-header.component.html',
  styleUrls: ['./coupon-sub-header.component.scss'],
})
export class CouponSubHeaderComponent implements OnInit {
  public title: string = '';
  public isMobile: boolean = window.innerWidth < 768;

  constructor(
    private readonly $modal: NzModalService,
    private readonly $drawer: NzDrawerService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.getTitle();
  }

  private getTitle(): void {
    this.$notification.titles.subscribe((res) => {
      this.title = res;
    });
  }

  public openFilter(): void {
    if (this.isMobile) {
      this.$drawer.create({
        nzHeight: '100vh',
        nzPlacement: 'top',
        nzTitle: null,
        nzFooter: null,
        nzClosable: false,
        nzContent: DrawerFilterCouponComponent,
      });
    } else {
      this.$modal.create({
        nzTitle: null,
        nzContent: ModalFilterCouponComponent,
        nzFooter: null,
        nzCentered: true,
        nzClosable: false,
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.isMobile = event.target.innerWidth < 768;
  }
}
