<nz-sider
  id="coupon-sidenav"
  nzCollapsible
  nzWidth="80%"
  [nzCollapsedWidth]="0"
  [(nzCollapsed)]="!isCollapsed"
  [nzZeroTrigger]="null"
  [nzTrigger]="null"
  nzTheme="light"
  (clickOutside)="onMenuClickOutside()"
  [ngClass]="isCollapsed ? 'hide-all' : ''"
>
  <section class="header">
    <nz-row class="m-b-2">
      <nz-col nzSpan="24">
        <img src="/assets/img/new-logo-gen-white.svg" alt="Logo da Gen branco" class="logo-image" />
      </nz-col>
    </nz-row>
    <nz-row>
      <nz-col nzSpan="24">
        <span class="coupon-menu-text white-color" (click)="openLoginModal()">
          {{ lead.name ? 'Olá, ' + lead.name : 'Entre ou cadastre-se' }}
        </span>
      </nz-col>
    </nz-row>
  </section>
  <ul nz-menu nzMode="inline" [nzInlineCollapsed]="!isCollapsed">
    <li nz-menu-item (click)="goTo('/')">
      <span class="coupon-menu-text">Início</span>
    </li>
    <li nz-menu-item (click)="goTo('/my-coupons')" *ngIf="lead.id">
      <span class="coupon-menu-text">Meus cupons</span>
    </li>
    <li nz-menu-item (click)="goTo('/stores')">
      <span class="coupon-menu-text">Lojas</span>
    </li>
    <li nz-submenu nzTitle="Categorias" class="coupon-menu-text">
      <ul>
        <li
          nz-menu-item
          *ngFor="let category of categories; trackBy: trackByFn"
          (click)="goToCategoryPage(category.id)"
          class="coupon-menu-text"
        >
          {{ category.name }}
        </li>
      </ul>
    </li>
    <li nz-menu-item (click)="logout()" *ngIf="lead.id">
      <span class="coupon-menu-text">Sair</span>
    </li>
  </ul>
</nz-sider>
