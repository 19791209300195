import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Mutation, Query } from 'apollo-angular';

import {
  GET_ALL_SELLERS_QUERY,
  GET_CURRENT_LEAD_QUERY,
  GET_COUPON_BY_ID_QUERY,
  GET_LEAD_MY_COUPONS_QUERY,
  GET_COUPONS_BY_SELLER_QUERY,
  GET_COUPONS_BY_LEAD_ID_QUERY,
  GET_COUPONS_BY_CATEGORY_ID_QUERY,
  CURRENT_LEAD_QUERY,
} from '../../graphql/queries/lead.queries';
import {
  CREATE_LEAD_MUTATION,
  SHARE_COUPON_MUTATION,
  LEAD_SEND_SMS_MUTATION,
  LEAD_LIKE_COUPON_MUTATION,
  LEAD_UNLIKE_COUPON_MUTATION,
  RESCUE_LEAD_COUPON_MUTATION,
  LEAD_VERIFY_AUTH_CODE_MUTATION,
  REDEEM_COUPON_BY_LEAD_MUTATION,
  CREATE_COUPON_AVALIATION_MUTATION,
} from '../../graphql/mutations/lead.mutations';
import { LeadModel } from '../../models/lead/lead.model';
import { CouponAvaliationsFidelityModel } from '../../models/fidelity/coupon-avaliations-fidelity.model';

@Injectable()
export class LeadService {
  constructor(private readonly apollo: Apollo) {}

  public getCurrentLead(): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_CURRENT_LEAD_QUERY,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getMyCoupons(filterForm?: any): Observable<any> {
    const variables = this.getVariables({}, filterForm);

    return this.apollo.watchQuery<Query>({
      query: GET_LEAD_MY_COUPONS_QUERY,
      variables,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getCouponsByLeadId(leadId?: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_COUPONS_BY_LEAD_ID_QUERY,
      variables: { leadId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public currentLead(): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: CURRENT_LEAD_QUERY,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getCouponsByCategoryId(leadId: string, id: string, filterForm?: any): Observable<any> {
    const variables = this.getVariables({ leadId, id }, filterForm);

    return this.apollo.watchQuery<Query>({
      query: GET_COUPONS_BY_CATEGORY_ID_QUERY,
      variables,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getCouponById(leadId: string, id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_COUPON_BY_ID_QUERY,
      variables: { leadId, id },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getAllSellers(leadId?: string, filterForm?: any): Observable<any> {
    const variables = this.getVariables({ leadId }, filterForm);

    return this.apollo.watchQuery<Query>({
      query: GET_ALL_SELLERS_QUERY,
      variables,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getCouponsBySellerId(sellerId: string, leadId?: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_COUPONS_BY_SELLER_QUERY,
      variables: { leadId, sellerId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public createLead(name: string, phone: string, email: string, cpf: string, origin?: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_LEAD_MUTATION,
      variables: { name, phone, email, cpf, origin },
    });
  }

  public sendSms(phoneNumber: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: LEAD_SEND_SMS_MUTATION,
      variables: { phoneNumber },
    });
  }

  public verifyAuthCode(phoneNumber: string, code: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: LEAD_VERIFY_AUTH_CODE_MUTATION,
      variables: { phoneNumber, code },
    });
  }

  public rescueCoupon(couponId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: RESCUE_LEAD_COUPON_MUTATION,
      variables: { couponId },
    });
  }

  public redeemCoupon(leadId: string, couponId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: REDEEM_COUPON_BY_LEAD_MUTATION,
      variables: { leadId, couponId },
    });
  }

  public likeCoupon(couponId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: LEAD_LIKE_COUPON_MUTATION,
      variables: { couponId },
    });
  }

  public unlikeCoupon(couponId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: LEAD_UNLIKE_COUPON_MUTATION,
      variables: { couponId },
    });
  }

  public shareCoupon(couponId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: SHARE_COUPON_MUTATION,
      variables: { couponId },
    });
  }

  public createCouponAvaliation(variables: Partial<CouponAvaliationsFidelityModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_COUPON_AVALIATION_MUTATION,
      variables,
    });
  }

  private getVariables(initialData: any, filterForm?: any): any {
    if (filterForm?.mallId) {
      initialData.mallId = filterForm.mallId;
    }

    if (filterForm?.orderBy) {
      initialData.orderBy = filterForm.orderBy;
    }

    return initialData;
  }
}
