import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input } from '@angular/core';

import { CouponFidelityModel } from '../../../models/fidelity/coupon-fidelity.model';

@Component({
  selector: 'app-modal-success-redeem-coupon',
  templateUrl: './modal-success-redeem-coupon.component.html',
  styleUrls: ['./modal-success-redeem-coupon.component.scss'],
})
export class ModalSuccessRedeemCouponComponent {
  @Input() public coupon: CouponFidelityModel;

  constructor(private readonly $modalRef: NzModalRef) {}

  public closeModal(): void {
    this.$modalRef.close();
  }
}
