<main id="avaliation-coupon">
  <section>
    <nz-row>
      <nz-col nzSpan="12">
        <span class="title-text">Avaliação</span>
      </nz-col>
      <nz-col nzSpan="12" class="endBlock">
        <img
          src="/assets/icons/xmark.svg"
          alt="Ícone para fechar o modal"
          class="pointer-cursor"
          (click)="closeModal(false)"
        />
      </nz-col>
    </nz-row>
  </section>

  <nz-divider></nz-divider>

  <section>
    <form [formGroup]="formGroup">
      <nz-row class="m-b-1-3">
        <nz-col nzSpan="24">
          <p class="no-margin-bottom description-text">
            Outros usuários querem saber o quanto esse cupom foi interessante para você.
          </p>
        </nz-col>
      </nz-row>
      <nz-row class="m-b-1">
        <nz-col nzSpan="24" class="center">
          <nz-rate formControlName="rating"></nz-rate>
        </nz-col>
      </nz-row>
      <nz-row>
        <nz-col nzSpan="24" class="centerBlock">
          <div class="bad-or-good">
            <div>
              <span class="average-label">Ruim</span>
            </div>
            <div>
              <span class="average-label">Bom</span>
            </div>
          </div>
        </nz-col>
      </nz-row>
      <!-- <nz-row>
        <nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label class="startBlock input-text m-b-1" [nzNoColon]="true" [nzSm]="24" nzFor="message">
              Escreva um comentário
            </nz-form-label>
            <nz-form-control
              [nzSm]="24"
              [nzXs]="24"
              nzErrorTip="Você ultrapassou o limite de caracteres permitidos."
              nzHasFeedback
            >
              <nz-textarea-count [nzMaxCharacterCount]="240">
                <textarea
                  rows="4"
                  id="message"
                  formControlName="message"
                  placeholder="Digite uma mensagem..."
                  nz-input
                ></textarea>
              </nz-textarea-count>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row> -->
    </form>
  </section>

  <nz-divider></nz-divider>

  <section>
    <nz-row>
      <nz-col nzSpan="12">
        <app-secondary-button (click)="closeModal(false)">Cancelar</app-secondary-button>
      </nz-col>
      <nz-col nzSpan="12" class="endBlock">
        <app-primary-button [disabled]="formGroup.invalid" (click)="closeModal(true)">Enviar</app-primary-button>
      </nz-col>
    </nz-row>
  </section>
</main>
