import { Router } from '@angular/router';
import { Component, Input, OnInit, input } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { SidenavModel } from 'libs/shared/src/lib/models/utils/sidenav.model';
import { SellerModel } from 'libs/shared/src/lib/models/sellers/sellers.model';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { ModalLogoutComponent } from 'libs/shared/src/lib/components/modals/modal-logout/modal-logout.component';
import { MAIN_SIDENAV, CONFIG_SIDENAV, MVP_SIDENAV } from 'libs/shared/src/lib/utils/side-nav';
import { AuthService } from '../../services/auth/auth-business/auth.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Input() isMvp: boolean = false;

  public routes: SidenavModel[] = MAIN_SIDENAV;
  private seller: SellerModel = new SellerModel();
  public isCollapsed: boolean = false;

  constructor(
    private $auth: AuthService,
    private readonly router: Router,
    private readonly $modal: NzModalService,
    private $notification: StateManagementService
  ) { }

  public ngOnInit(): void {
    this.setRouteConfig();
    this.getSeller();
    this.getUrlPath();
  }

  public logout(): void {
    this.$modal
      .create({
        nzContent: ModalLogoutComponent,
      })
      .afterClose.subscribe((res: boolean) => {
        if (res) {
          this.$auth.logout();
        }
      });
  }

  private getSeller(): void {
    this.$notification.sellers.subscribe((res) => {
      if (res) {
        this.seller = res;

        if (
          this.router.isActive('/internal/settings', {
            paths: 'subset',
            queryParams: 'subset',
            matrixParams: 'ignored',
            fragment: 'ignored',
          })
        ) {
          this.routes[5].disable = this.seller?.status !== 'ACTIVE';
        } else {
          this.routes[1].childrens[2].disable = this.seller?.status !== 'ACTIVE';
          this.routes[5].childrens[0].disable = !this.seller?.id;
        }
      }
    });
  }

  private setRouteConfig(): void {


    if (this.isMvp) {
      if (
        this.router.isActive('/internal/settings', {
          paths: 'subset',
          queryParams: 'subset',
          matrixParams: 'ignored',
          fragment: 'ignored',
        })
      ) {
        this.routes = CONFIG_SIDENAV;
      } else {
        this.routes = MVP_SIDENAV;
      }

      this.router.events.subscribe((_) => {
        if (
          this.router.isActive('/internal/settings', {
            paths: 'subset',
            queryParams: 'subset',
            matrixParams: 'ignored',
            fragment: 'ignored',
          })
        ) {
          this.routes = CONFIG_SIDENAV;
          this.routes[5].disable = this.seller?.status !== 'ACTIVE';
        } else {
          this.routes = MVP_SIDENAV;
          this.routes[1].childrens[2].disable = this.seller?.status !== 'ACTIVE';
          this.routes[5].childrens[0].disable = !this.seller?.id;
        }
      });
    } else {
      if (
        this.router.isActive('/internal/settings', {
          paths: 'subset',
          queryParams: 'subset',
          matrixParams: 'ignored',
          fragment: 'ignored',
        })
      ) {
        this.routes = CONFIG_SIDENAV;
      } else {
        this.routes = MAIN_SIDENAV;
      }

      this.router.events.subscribe((_) => {
        if (
          this.router.isActive('/internal/settings', {
            paths: 'subset',
            queryParams: 'subset',
            matrixParams: 'ignored',
            fragment: 'ignored',
          })
        ) {
          this.routes = CONFIG_SIDENAV;
          this.routes[5].disable = this.seller?.status !== 'ACTIVE';
        } else {
          this.routes = MAIN_SIDENAV;
          this.routes[1].childrens[2].disable = this.seller?.status !== 'ACTIVE';
          this.routes[5].childrens[0].disable = !this.seller?.id;
        }
      });
    }


  }

  public getUrlPath(): null | undefined {
    let path = window.location.pathname.split('/');
    if (path[2] === 'settings') {
      this.isCollapsed = false;
      return null;
    } else {
      return undefined;
    }
  }

  public openHandler(title: string): void {
    this.routes.forEach((route) => {
      if (route.title !== title) {
        route.isOpen = false;
      }
    });
  }

  public notifyChange(event: boolean): void {
    this.$notification.setCollapsedSidenav(event);
  }
}
