import { gql } from 'apollo-angular';

const CREATE_CONTACT_MUTATION = gql`
  mutation createContactsToSeller($contacts: CreateContactsInputs!, $seller: CreateSellerInput!) {
    createContactsToSeller(contacts: $contacts, seller: $seller) {
      id
      fullName
      phoneNumber
      isLegalEntity
      businessName
      seller {
        business_name
      }
      address {
        street
        state
      }
    }
  }
`;

const UPDATE_CONTACT_MUTATION = gql`
  mutation updateContact($contactId: String!, $sellerId: String!, $contact: UpdateContactInput!) {
    updateContact(contactId: $contactId, sellerId: $sellerId, contact: $contact) {
      id
      fullName
      created_at
      cpf
      cnpj
      businessName
      address {
        state
        street
        number
        neighborhood
        city
        country
      }
      seller {
        id
        business_name
      }
    }
  }
`;

const DELETE_CONTACT_MUTATION = gql`
  mutation deleteContact($contactId: String!) {
    deleteContact(contactId: $contactId) {
      id
      fullName
      sellerId
      customerId
      deleted
    }
  }
`;

export { CREATE_CONTACT_MUTATION, UPDATE_CONTACT_MUTATION, DELETE_CONTACT_MUTATION };
