import { gql } from 'apollo-angular';

const DEFAULT_FIELDS = `
  id
  code
  name
  expiration_date
  status
  value
  amount
  mall {
    id
    name
  }
  description
  start_date
  user_usage_limit
  typeDiscount
  viewCoupon
  app_view
  coupon_image
  buy_value_min
  buy_value_max
  isFavorite
  cumulative
  seller {
    id
    business_name
  }
  categories {
    id
    name
    status
  }
  coupon_type {
    id
    name
    status
  }
  redeemed_coupons {
    id
  }
`;

const COUPONS_QUERY = gql`
  query Coupons($filters: FilterCouponParamsInput, $pagination: PaginationParams) {
    coupons(filters: $filters, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        id
        code
        name
        typeDiscount
        expiration_date
        amount
        status
        value
        status
        coupon_image
        redeemed_coupons {
          id
        }
        mall {
          id
        }
        seller {
          id
          business_name
        }
        categories {
          name
        }
      }
    }
  }
`;

const COUPON_BY_ID_QUERY = gql`
  query Coupon($id: String!) {
    coupon(id: $id) {
      ${DEFAULT_FIELDS}
    }
  }
`;

const CUSTOMER_COUPONS_BY_SELLER_QUERY = gql`
  query CustomerCouponsBySeller($sellerId: String!, $startDate: DateTime, $endDate: DateTime, $couponId: String!) {
    customerCouponsBySeller(sellerId: $sellerId, startDate: $startDate, endDate: $endDate, couponId: $couponId) {
      redeemed_coupons {
        id
        redeemed_at
        date_redeemed_seller
        buy_value_seller
        couponId
        coupon {
          id
          code
        }
        customer {
          id
          name
        }
        lead {
          id
          name
        }
      }
      rescued_coupons {
        id
        dateRescuedAt
        couponId
        sellerId
        coupon {
          id
          code
        }
        lead {
          id
          name
        }
        customer {
          id
          name
        }
      }
    }
  }
`;

const ALL_CATEGORIES_COUPON_QUERY = gql`
  query AllCategoriesCoupon {
    allCategoriesCoupon {
      id
      name
    }
  }
`;

const CATEGORIES_COUPON_QUERY = gql`
  query Categories {
    categories {
      id
      name
    }
  }
`;

const COUPONS_TYPE_QUERY = gql`
  query CouponsType {
    couponsType {
      id
      name
    }
  }
`;

const MALLS_QUERY = gql`
  query Malls($filters: FiltersMallParamsInput, $pagination: PaginationParams) {
    malls(filters: $filters, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        id
        name
        typeMall
        cnpj
        status
      }
    }
  }
`;

const COUPON_ANALYSIS_QUERY = gql`
  query CouponAnalysis($couponId: String!) {
    couponAnalysis(couponId: $couponId) {
      couponViews
      collectedCoupons
      roi
      couponLikes
      couponShare
      redeemedCoupons
    }
  }
`;

const SELLER_COUPON_ANALYSIS_QUERY = gql`
  query SellerCouponAnalysis($sellerId: String!) {
    sellerCouponAnalysis(sellerId: $sellerId) {
      totalCouponViews
      totalCouponLikes
      totalCollectedCoupons
      totalRedeemedCoupons
      totalRoi
      totalSales
      totalShare
    }
  }
`;

const CUSTOMER_COUPONS_QUERY = gql`
  query CustomerCoupons($customerId: String!) {
    customerCoupons(customerId: $customerId) {
      actives {
        ${DEFAULT_FIELDS}
      }
      inactives {
        ${DEFAULT_FIELDS}
      }
    }
  }
`;

const GET_COUPONS_BY_CATEGORY_ID_QUERY = gql`
  query CategoryToCoupons($id: String!) {
    categoryToCoupons(id: $id) {
      name
      coupons {
        ${DEFAULT_FIELDS}
      }
    }
  }
`;

const PARKING_ACCOUNT_QUERY = gql`
  query ParkingAccount($id: String!) {
    parkingAccount(id: $id) {
      id
      url
      seller {
        id
      }
      mall {
        id
        name
      }
      insertedAt
      updatedAt
      parkingUser {
        id
        auth
        main
        authEmail
      }
      mall {
        name
        typeMall
        latitude
        longitude
        cnpj
        status
        banner
        address {
          city
          state
          neighborhood
          complement
          street
          number
          postalCode
          countryCode
        }
      }
    }
  }
`;

const PARKING_ACCOUNTS_QUERY = gql`
  query ParkingAccounts($filters: FilterParkingAccountParamsInput, $pagination: PaginationParams) {
    parkingAccounts(filters: $filters, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        id
        insertedAt
        updatedAt
        mall {
          id
          name
          typeMall
          cnpj
          status
        }
      }
    }
  }
`;

const PARKING_CAMPAIGNS_QUERY = gql`
  query ParkingCampaigns($filters: FiltersParkingCampaignParamsInput, $pagination: PaginationParams) {
    parkingCampaigns(filters: $filters, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        id
        name
        discountType
        isActive
        discountValue
        sellers {
          id
          person {
            name
          }
          company {
            businessName
          }
        }
      }
    }
  }
`;

const PARKING_TABLE_QUERY = gql`
  query parkingTables {
    parkingTables {
      id
      mallId
      mall {
        id
        name
        status
        cnpj
        typeMall
      }
      parkingPriceTable {
        id
        time
        amount
        description
        insertedAt
        updatedAt
      }
      insertedAt
      updatedAt
    }
  }
`;

const PARKING_CAMPAIGN_QUERY = gql`
  query ParkingCampaign($id: String!) {
    parkingCampaign(id: $id) {
      id
      name
      description
      validPurchasesAge
      discountType
      discountValue
      minPurchaseValue
      restrictParticipantStores
      restrictByHourOfDay
      isActive
      timeRestrictions {
        id
        parkingCampaignId
        weekdays
        startTime
        endTime
      }
      mall {
        id
        name
      }
      sellers {
        id
        cnpj
        phone
        cpf
        person {
          name
        }
        company {
          businessName
        }
      }
      startDate
      endDate
      insertedAt
      updatedAt
    }
  }
`;

export {
  PARKING_TABLE_QUERY,
  PARKING_ACCOUNT_QUERY,
  PARKING_ACCOUNTS_QUERY,
  MALLS_QUERY,
  COUPONS_QUERY,
  PARKING_CAMPAIGN_QUERY,
  PARKING_CAMPAIGNS_QUERY,
  COUPON_BY_ID_QUERY,
  COUPONS_TYPE_QUERY,
  COUPON_ANALYSIS_QUERY,
  CUSTOMER_COUPONS_QUERY,
  CATEGORIES_COUPON_QUERY,
  ALL_CATEGORIES_COUPON_QUERY,
  SELLER_COUPON_ANALYSIS_QUERY,
  CUSTOMER_COUPONS_BY_SELLER_QUERY,
  GET_COUPONS_BY_CATEGORY_ID_QUERY,
};
