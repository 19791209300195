import { LeadService } from './../lead/lead.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { environment } from '@gen/environments';
import {
  GET_SELLER,
  LIST_SELLERS_QUERY,
  GET_SELLER_BUSINESS,
  GET_SELLER_DOCUMENTS,
  LIST_PLATFORM_TERMS_QUERY,
  GET_LAST_PLATFORM_TERM_QUERY,
  GET_EXTERNAL_CONSULT_DETAILS_QUERY,
  LIST_SELLERS_TO_COUPON_QUERY,
  GET_DEFAULT_ACTIVITIES_QUERY,
  GET_SELLER_EXTERNAL_CONSULT,
  LIST_SELLERS_TO_CREATE_EDIT_COUPON_QUERY,
} from '../../graphql/queries/seller.queries';
import {
  BAN_SELLER_MUTATION,
  EDIT_SELLER_MUTATION,
  BLOCK_SELLER_MUTATION,
  DECLINE_SELLER_MUTATION,
  UNBLOCK_SELLER_MUTATION,
  APPROVE_SELLER_MUTATION,
  REPROCESS_SELLER_MUTATION,
  UPDATE_STORE_PREFERENCES_MUTATION,
  ACCEPT_SAAS_PLATFORM_TERM_MUTATION,
  CREATE_PLATFORM_TERM_MUTATION,
  UPLOAD_SELLER_DOCUMENT_MUTATION,
  FILL_SELLER_ONBOARDING_MUTATION,
  UPDATE_ONBOARDING_STEP_MUTATION,
  DELETE_SELLER_DOCUMENT_MUTATION,
  CREATE_SELLER_SYNAPSES_MUTATION,
} from '../../graphql/mutations/seller.mutations';
import { ExternalConsult, SellerModel, SellersDataModel } from '../../models/sellers/sellers.model';
import { ResponseModel } from '../../models/response/response.model';
import { FilterSellersParams } from '../../models/sellers/filter-sellers-params.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { ErrorService } from '../error/error.service';
import { Message } from '../../utils/message';
import { InAnalysisComponent } from '../../components/onboarding/in-analysis/in-analysis.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { AccountCreatedComponent } from '../../components/onboarding/account-created/account-created.component';
import { PaginationModel } from '../../models/pagination/pagination.model';

@Injectable()
export class SellersService {
  public marketplaceId?: string = environment.marketplaceId;
  public sellerSetted: boolean = false;

  constructor(
    private readonly apollo: Apollo,
    private $lead: LeadService,
    private $error: ErrorService,
    private $storage: AngularFireStorage,
    private router: Router,
    private $modal: NzModalService,
    private $functions: AngularFireFunctions,
    private readonly fireStore: AngularFirestore,
    private readonly $notification: StateManagementService
  ) {}

  public getSellerImageURL(sellerId: string): Observable<string | undefined> {
    return this.$storage.ref(`sellers/${sellerId}/avatar`).getDownloadURL();
  }

  public getAndSetSellerImageUrl(seller: SellerModel): void {
    this.$storage
      .ref(`sellers/${seller.id!}/avatar`)
      .getDownloadURL()
      .subscribe((imageUrl) => {
        this.$notification.setSellerImageUrl(imageUrl);
      });
  }

  public getSellerImageBannerURL(sellerId: string): Observable<string | undefined> {
    return this.$storage.ref(`sellers/${sellerId}/banner`).getDownloadURL();
  }

  public getSeller(sellerId?: string): Observable<SellerModel> | undefined {
    return this.fireStore
      .doc(`marketplaces/${this.marketplaceId}/sellers/${sellerId}`)
      .valueChanges() as Observable<SellerModel>;
  }

  public setSellerInfo(sellerId: string): void {
    if (sellerId && sellerId !== undefined && !this.sellerSetted) {
      this.sellerSetted = true;
      const seller = { id: sellerId };
      seller.id = sellerId;
      this.$notification.setSeller(seller);
    }
  }

  public updateExternalSeller(data: Partial<SellerModel>): Observable<ResponseModel<SellerModel>> {
    return this.$functions.httpsCallable('updateSeller')(data);
  }

  public sellerWithEmailExists(email: string): Observable<ResponseModel<SellerModel>> {
    return this.$functions.httpsCallable('sellerWithEmailExists')(email);
  }

  public uploadSellerDocuments(params: any): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPLOAD_SELLER_DOCUMENT_MUTATION,
      variables: { params },
    });
  }

  public removeSellerDocuments(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: DELETE_SELLER_DOCUMENT_MUTATION,
      variables: { id },
    });
  }

  public getSellerDocuments(id: string): Observable<ApolloQueryResult<SellerModel>> {
    return this.apollo.watchQuery<SellerModel>({
      query: GET_SELLER_DOCUMENTS,
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }).valueChanges;
  }

  public getExternalSellerConsult(id: string): Observable<any> {
    return this.apollo.watchQuery<ExternalConsult>({
      query: GET_SELLER_EXTERNAL_CONSULT,
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }).valueChanges;
  }

  public getSellerImageLogoURL(sellerId: string): Observable<string | undefined> {
    return this.$storage.ref(`sellers/${sellerId}/avatar`).getDownloadURL();
  }

  public listSellersToCoupon(): Observable<ApolloQueryResult<SellersDataModel>> {
    return this.apollo.watchQuery<SellersDataModel>({
      query: LIST_SELLERS_TO_COUPON_QUERY,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public listSellersToCouponCreateEdit(): Observable<ApolloQueryResult<SellersDataModel>> {
    return this.apollo.watchQuery<SellersDataModel>({
      query: LIST_SELLERS_TO_CREATE_EDIT_COUPON_QUERY,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getSellerList(
    filter: FilterSellersParams,
    pagination: PaginationModel
  ): Observable<ApolloQueryResult<SellersDataModel>> {
    return this.apollo.watchQuery<SellersDataModel>({
      query: LIST_SELLERS_QUERY,
      fetchPolicy: 'network-only',
      variables: { filter, pagination },
    }).valueChanges;
  }

  public getSellerById(id: string): Observable<ApolloQueryResult<SellerModel>> {
    return this.apollo.watchQuery<SellerModel>({
      query: GET_SELLER,
      fetchPolicy: 'network-only',
      variables: { id },
    }).valueChanges;
  }

  public getSellerByIdBusiness(id: string): Observable<any> {
    return this.apollo.watchQuery<SellerModel>({
      query: GET_SELLER_BUSINESS,
      fetchPolicy: 'network-only',
      variables: { id },
    }).valueChanges;
  }

  public listLastTerm(): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery<any>({
      query: GET_LAST_PLATFORM_TERM_QUERY,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public listTerms(filters: any): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery<any>({
      query: LIST_PLATFORM_TERMS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { filters },
    }).valueChanges;
  }

  public getExternalConsultDetails(id: string): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery<any>({
      query: GET_EXTERNAL_CONSULT_DETAILS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }).valueChanges;
  }

  public getDefaultActivities(): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery<any>({
      query: GET_DEFAULT_ACTIVITIES_QUERY,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public banSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: BAN_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public blockSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: BLOCK_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public unblockSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UNBLOCK_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public reprocessSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: REPROCESS_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public approveSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: APPROVE_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public declineSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: DECLINE_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public editSeller(params: any): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: EDIT_SELLER_MUTATION,
      variables: {
        params,
      },
    });
  }

  public updateStorePreferences(sellerId: string, params: any): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_STORE_PREFERENCES_MUTATION,
      variables: {
        params,
        sellerId,
      },
    });
  }

  public acceptTerm(sellerId: string, platformTermId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: ACCEPT_SAAS_PLATFORM_TERM_MUTATION,
      variables: {
        sellerId,
        platformTermId,
      },
    });
  }

  public createTerm(params: any): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_PLATFORM_TERM_MUTATION,
      variables: {
        params,
      },
    });
  }

  public fillSellerOnboarding(params: any): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: FILL_SELLER_ONBOARDING_MUTATION,
      variables: {
        params,
      },
    });
  }

  public updateOnboardingStep(userId: string, step: any): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_ONBOARDING_STEP_MUTATION,
      variables: {
        userId,
        step,
      },
    });
  }

  public getAndSetSeller(sellerId: string): void {
    this.getSellerByIdBusiness(sellerId).subscribe({
      next: (res) => {
        if (res?.data?.seller) {
          let seller = JSON.parse(JSON.stringify(res.data.seller));

          this.$notification.setSeller(seller);
        }
      },
      error: (error) => {
        this.$error.errorHandling(error, Message.ERROR_CONECTION);
      },
    });
  }

  public getSellersMarketplace(isMobile: boolean): void {
    this.$lead.getAllSellers().subscribe({
      next: (res) => {
        if (res?.data?.allSellersCoupon) {
          let sellers = JSON.parse(JSON.stringify(res.data.allSellersCoupon));

          sellers = sellers.map((option) => {
            let nameArray: string[] = option.business_name.split(' ');
            option.business_name = isMobile ? nameArray[0] : nameArray[0] + ' ' + nameArray[1];

            return option;
          });

          this.$notification.setSellersList(sellers);
        }
      },
      error: (error) => {
        this.$error.errorHandling(error, Message.ERROR_CONECTION);
      },
    });
  }

  public createSellerSynapses(params: any): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_SELLER_SYNAPSES_MUTATION,
      variables: { params },
    });
  }

  public isSellerNotActive(seller: SellerModel): boolean {
    if (!seller.id) {
      return true;
    }

    if (['NEW', 'IN_PROGRESS', 'IN_ANALYSIS'].includes(seller?.status)) {
      return true;
    }

    return false;
  }

  public showModalInAnalysis(): void {
    this.$modal.create({
      nzWidth: 520,
      nzContent: InAnalysisComponent,
      nzCentered: true,
      nzClosable: false,
      nzTitle: null,
      nzFooter: null,
    });
  }

  public showModalActiveAccount(): void {
    this.$modal
      .create({
        nzWidth: 520,
        nzContent: AccountCreatedComponent,
        nzCentered: true,
        nzClosable: false,
        nzTitle: null,
        nzFooter: null,
      })
      .afterClose.subscribe((type) => {
        if (type === 'NOW') {
          this.router.navigate(['/internal/onboarding']);
        }
      });
  }

  public goToOnboarding(seller: SellerModel): void {
    if (seller.status === 'IN_ANALYSIS') {
      this.showModalInAnalysis();
    } else {
      this.showModalActiveAccount();
    }
  }
}
