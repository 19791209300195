import { gql } from '@apollo/client/core';

const COUPON_FIELDS = `
  id
  code
  name
  value
  description
  typeDiscount
  coupon_image
  isFavorite
  totalAvaliations
  couponRating
  sellerId
  expiration_date
  user_usage_limit
  buy_value_max
  buy_value_min
  status
  rescued
  redeemed
  redeemLimit
  cumulative
  redeemedAt
  mall {
    name
  }
  seller {
    image_url
    business_name
  }
  coupon_avaliations {
    id
    rating
    message
    created_at
    leadId
    lead {
      name
    }
    customer {
      name
    }
  }
`;

const GET_CURRENT_LEAD_QUERY = gql`
  query Lead {
    lead {
      id
      name
      phone
      email
      cpf
    }
  }
`;

const CURRENT_LEAD_QUERY = gql`
  query currentLead {
    currentLead {
      id
      name
      phone
      email
      cpf
      password
      active
      created_at
      updated_at
      origin
    }
  }
`;

const GET_LEAD_MY_COUPONS_QUERY = gql`
  query LeadRescuedCoupon($mallId: Float, $orderBy: String) {
    leadRescuedCoupon(mallId: $mallId, orderBy: $orderBy) {
      actives {
        ${COUPON_FIELDS}
      }
      inactives {
        ${COUPON_FIELDS}
      }
    }
  }
`;

const GET_COUPONS_BY_LEAD_ID_QUERY = gql`
  query couponsLead($leadId: String) {
    couponsLead(leadId: $leadId) {
      ${COUPON_FIELDS}
    }
  }
`;

const GET_COUPONS_BY_CATEGORY_ID_QUERY = gql`
  query AllCategoriesCouponLead($leadId: String, $id: String, $mallId: Float, $orderBy: String) {
    allCategoriesCouponLead(leadId: $leadId, id: $id, mallId: $mallId, orderBy: $orderBy) {
      id
      name
      coupons {
        ${COUPON_FIELDS}
      }
    }
  }
`;

const GET_COUPON_BY_ID_QUERY = gql`
  query CouponLead($id: String!, $leadId: String) {
    couponLead(id: $id, leadId: $leadId) {
      ${COUPON_FIELDS}
    }
  }
`;

const GET_ALL_SELLERS_QUERY = gql`
  query AllSellersCoupon($leadId: String, $mallId: Float, $orderBy: String) {
    allSellersCoupon(leadId: $leadId, mallId: $mallId, orderBy: $orderBy) {
      id
      business_name
      image_url
      address {
        street
        state
        city
        number
        neighborhood
        zipCode
      }
      coupons {
        id
      }
    }
  }
`;

const GET_COUPONS_BY_SELLER_QUERY = gql`
  query SellerToCoupon($sellerId: String!, $leadId: String) {
    sellerToCoupon(sellerId: $sellerId, leadId: $leadId) {
      id
      business_name
      image_url
      address {
        street
        state
        city
        number
        neighborhood
        zipCode
      }
      coupons {
        ${COUPON_FIELDS}
      }
    }
  }
`;

export {
  GET_ALL_SELLERS_QUERY,
  GET_CURRENT_LEAD_QUERY,
  GET_COUPON_BY_ID_QUERY,
  GET_LEAD_MY_COUPONS_QUERY,
  GET_COUPONS_BY_SELLER_QUERY,
  GET_COUPONS_BY_LEAD_ID_QUERY,
  GET_COUPONS_BY_CATEGORY_ID_QUERY,
  CURRENT_LEAD_QUERY,
};
