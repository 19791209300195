<nz-sider
  [nzCollapsedWidth]="90"
  [nzWidth]="279"
  nzTheme="light"
  nzCollapsible
  [(nzCollapsed)]="isCollapsed"
  (nzCollapsedChange)="notifyChange($event)"
  [nzTrigger]="getUrlPath()"
>
  <div class="logo-content">
    <img
      src="../../assets/images/logo-gen-white.svg"
      routerLink="/internal"
      class="pointer-cursor"
      alt="Logo da empresa"
    />
  </div>
  <ul nz-menu nzMode="inline" class="sider-menu">
    <ng-container *ngFor="let item of routes">
      <li
        nz-menu-item
        (click)="item.title === 'Sair' ? logout() : ''"
        *ngIf="!item?.childrens; else menuGroup"
        [nzMatchRouter]="item.title === 'Sair' ? false : true"
        routerLinkActive="active"
        [nzDisabled]="item.disable"
      >
        <div class="w-100">
          <a [routerLink]="item.route" *ngIf="item.title; else nzDivider" class="d-flex">
            <span *ngIf="item?.icon" nz-icon [nzType]="item?.icon"></span>
            <img nz-icon *ngIf="item?.svg" [src]="item?.svg" alt="Icones aleatórios" />
            <span class="colorGreen">{{ item.title }}</span>
          </a>
        </div>
      </li>
      <ng-template #menuGroup>
        <li
          nz-submenu
          [(nzOpen)]="item.isOpen"
          nz-tooltip
          [nzTooltipTitle]="item.disable ? 'Ative sua conta' : ''"
          nzTooltipPlacement="right"
          (nzOpenChange)="openHandler(item.title)"
          [nzTitle]="titleSubmenu"
          [nzDisabled]="item.disable"
        >
          <ng-template #titleSubmenu>
            <span *ngIf="item?.icon" class="m-r-0" nz-icon [nzType]="item?.icon"></span>
            <img nz-icon class="img-icon m-r-0" *ngIf="item?.svg" [src]="item?.svg" alt="Icones aleatórios" />
            <span>{{ item.title }}</span>
          </ng-template>
          <ul>
            <ng-container *ngFor="let subItem of item.childrens">
              <li
                nz-menu-item
                nz-tooltip
                [nzTooltipTitle]="subItem.disable ? 'Ative sua conta' : ''"
                nzTooltipPlacement="right"
                *ngIf="!subItem?.childrens"
                [nzMatchRouter]="true"
                routerLinkActive="active"
                [nzDisabled]="subItem.disable"
              >
                <a [routerLink]="subItem.route">
                  <span nz-icon *ngIf="subItem.icon" [nzType]="subItem.icon" nzTheme="outline"></span>
                  <span>{{ subItem.title }}</span>
                </a>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-template>
    </ng-container>
    <ng-template #nzDivider>
      <nz-divider class="sidenav-divider"></nz-divider>
    </ng-template>
  </ul>
</nz-sider>
<div class="complement-sider"></div>
