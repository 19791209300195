<nz-header id="coupon-header">
  <nz-row>
    <nz-col [nzXs]="2" [nzSm]="2" [nzMd]="8" [nzLg]="8" [nzXl]="8" [nzXXl]="8" class="mobile">
      <div *ngIf="activeRoute !== '/home'" class="pointer-cursor" (click)="goBack()">
        <img src="/assets/img/chevron-left.svg" alt="Seta para voltar a página anterior" />
      </div>
    </nz-col>
    <nz-col [nzXs]="2" [nzSm]="2" [nzMd]="8" [nzLg]="8" [nzXl]="8" [nzXXl]="8" class="desktop">
      <img src="/assets/img/main-logo.svg" alt="Logo da Gen padrão" class="img-logo" />
    </nz-col>
    <nz-col [nzXs]="20" [nzSm]="20" [nzMd]="8" [nzLg]="8" [nzXl]="8" [nzXXl]="8" class="centerBlock mobile">
      <img src="/assets/img/main-logo.svg" alt="Logo da Gen padrão" class="img-logo" />
    </nz-col>
    <nz-col [nzXs]="20" [nzSm]="20" [nzMd]="8" [nzLg]="8" [nzXl]="8" [nzXXl]="8" class="d-flex centerBlock desktop">
      <div class="m-r-2">
        <span routerLink="/" class="header-text pointer-cursor">Início</span>
      </div>
      <div class="m-r-2" nz-dropdown [nzDropdownMenu]="categoryMenu">
        <span class="header-text pointer-cursor">Categorias</span>
      </div>
      <nz-dropdown-menu #categoryMenu="nzDropdownMenu">
        <ul nz-menu>
          <li
            nz-menu-item
            *ngFor="let category of categories; trackBy: trackByFn"
            (click)="goToCategoryPage(category.id)"
            class="coupon-menu-text"
          >
            {{ category.name }}
          </li>
        </ul>
      </nz-dropdown-menu>
      <div>
        <span routerLink="/stores" class="header-text pointer-cursor">Lojas</span>
      </div>
    </nz-col>
    <nz-col [nzXs]="2" [nzSm]="2" [nzMd]="8" [nzLg]="8" [nzXl]="8" [nzXXl]="8" class="endBlock">
      <div class="pointer-cursor mobile" (click)="toggleMenu()">
        <img src="/assets/icons/menu.svg" alt="Ícone de menu" />
      </div>
      <app-primary-button class="desktop" *ngIf="!lead.id" (click)="openLoginModal()">
        Entre ou cadastre-se
      </app-primary-button>
      <div class="pointer-cursor self-center desktop" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
        <div class="custom-dot" *ngIf="lead.id">
          <span>{{ lead?.name?.toUpperCase()?.substring(0, 1) }}</span>
        </div>
      </div>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu class="drop-menu">
          <li nz-menu-item class="primary-color coupon-menu-text lead-menu-text padding-0">
            {{ 'Olá, ' + lead.name }}
          </li>
          <nz-divider class="coupon-divider"></nz-divider>
          <li nz-menu-item routerLink="/my-coupons" class="coupon-menu-text hover-menu padding-0">Meus cupons</li>
          <li nz-menu-item *ngIf="lead.id" (click)="logout()" class="hover-menu padding-0">
            <img src="/assets/icons/logout.svg" alt="Ícone para sair" class="m-r-0-1" />
            <span class="coupon-menu-text">Sair</span>
          </li>
        </ul>
      </nz-dropdown-menu>
    </nz-col>
  </nz-row>
</nz-header>
