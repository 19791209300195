import { CommonModule, registerLocaleData } from '@angular/common';
import { NZ_I18N, pt_BR } from 'ng-zorro-antd/i18n';
import { CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';

import { SharedModule } from '@gen/shared';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import pt from '@angular/common/locales/pt';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

registerLocaleData(pt);

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserAnimationsModule, BrowserModule, CommonModule, AppRoutingModule, SharedModule.forRoot()],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt',
      multi: true,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
      multi: true,
    },
    {
      provide: NZ_I18N,
      useValue: pt_BR,
      multi: true,
    },
    StateManagementService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
