import { gql } from 'apollo-angular';

const CREATE_SEGMENTATION_MUTATION = gql`
  mutation CreateSegmentation($name: String!, $description: String!, $contactIds: [String!], $sellerId: String) {
    createSegmentation(name: $name, description: $description, contactIds: $contactIds, sellerId: $sellerId) {
      id
      created_at
      updated_at
      name
      description
      contacts {
        id
        fullName
      }
      campaigns {
        id
        created_at
      }
      status
    }
  }
`;

const UPDATE_SEGMENTATION_MUTATION = gql`
  mutation UpdateSegmentation(
    $id: String!
    $name: String!
    $description: String!
    $contactIds: [String!]
    $sellerId: String
  ) {
    updateSegmentation(id: $id, name: $name, description: $description, contactIds: $contactIds, sellerId: $sellerId) {
      id
      created_at
      updated_at
      name
      description
      contacts {
        id
        fullName
      }
      campaigns {
        id
        created_at
      }
      status
    }
  }
`;

const DELETE_SEGMENTATION_MUTATION = gql`
  mutation DeleteSegmentation($id: String!) {
    deleteSegmentation(id: $id) {
      id
    }
  }
`;

export { CREATE_SEGMENTATION_MUTATION, UPDATE_SEGMENTATION_MUTATION, DELETE_SEGMENTATION_MUTATION };
