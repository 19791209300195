<section id="coupon-sub-header" *ngIf="title && (title !== 'DETAILS' || !isMobile)">
  <nz-row>
    <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8" [nzLg]="8" [nzXl]="8" [nzXXl]="8" class="self-center desktop">
      <nz-breadcrumb [nzAutoGenerate]="true">
        <nz-breadcrumb-item>
          <a routerLink="/">Início</a>
        </nz-breadcrumb-item>
      </nz-breadcrumb>
    </nz-col>
    <nz-col
      [nzXs]="24"
      [nzSm]="24"
      [nzMd]="8"
      [nzLg]="8"
      [nzXl]="8"
      [nzXXl]="8"
      class="d-flex centerBlock"
      *ngIf="title !== 'DETAILS'"
    >
      <h1 class="no-margin-bottom">{{ title }}</h1>
    </nz-col>
    <nz-col
      [nzXs]="24"
      [nzSm]="24"
      [nzMd]="8"
      [nzLg]="8"
      [nzXl]="8"
      [nzXXl]="8"
      class="endBlock"
      *ngIf="title !== 'DETAILS'"
    >
      <div class="d-flex pointer-cursor" (click)="openFilter()">
        <span class="filter-text m-r-1">Filtro</span>
        <img src="/assets/icons/filter.svg" alt="Logo da Gen padrão" />
      </div>
    </nz-col>
  </nz-row>
</section>
